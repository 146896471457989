import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import classnames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'

const Bubble = styled.div`
  border-radius: 20px;
  padding: 1em;

  position: relative;
  background: var(--white);
  -webkit-box-shadow: inset 0px 0px 0px 1px var(--gold);
  -moz-box-shadow: inset 0px 0px 0px 1px var(--gold);
  box-shadow: inset 0px 0px 0px 1px var(--gold);

  & p {
    text-align: justify;
  }

  &.left {
    margin-right: 30px;

    @media (max-width: 768px) {
      margin-right: 0px;
      margin-left: 30px;
    }
  }
  &.right {
    margin-left: 30px;
  }
  &.left::after {
    content: '';
    position: absolute;
    right: -40px;
    top: 30px;
    z-index: 5;
    background: var(--white);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 40px;
    border-color: transparent transparent transparent var(--gold);
    @media (max-width: 768px) {
      left: -30px;
      border-width: 10px 0 10px 20px;

      border-color: transparent transparent transparent var(--dark);
    }
  }
  &.right::after {
    content: '';
    position: absolute;
    left: -40px;
    top: 30px;
    z-index: 5;
    background: var(--white);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 40px 10px 0;
    border-color: transparent var(--gold) transparent transparent;
    @media (max-width: 768px) {
      left: -30px;
      border-width: 10px 0 10px 20px;

      border-color: transparent transparent transparent var(--dark);
    }
  }
`

export default class History extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            page: contentfulAboutPage {
              history {
                text {
                  g: childMarkdownRemark {
                    html
                  }
                }
              }
              historyStart
            }
          }
        `}
        render={({ page }) => (
          <div className="py-5">
            <Container>
              <h3 className="text-md-center text-primary mb-5">
                Proteo History
              </h3>
              <div className="middle-line">
                {' '}
                {
                  <Row>
                    {page.history.map((item, i) => (
                      <Col
                        md="6"
                        className={classnames('mb-3', {
                          'offset-md-6': i % 2 === 1,
                        })}
                        key={i}
                      >
                        <Bubble
                          className={`${i % 2 === 1 ? 'right' : 'left'}`}
                          dangerouslySetInnerHTML={{ __html: item.text.g.html }}
                        />
                      </Col>
                    ))}
                  </Row>
                }
              </div>
              <Row>
                {' '}
                <Col>
                  <div className="text-md-center text-left mt-5">
                    <p className="bg-dark d-inline-block text-white px-md-5  px-2 py-1 rounded">
                      {page.historyStart}
                    </p>
                  </div>{' '}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      />
    )
  }
}
