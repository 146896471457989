import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { StaticQuery, graphql } from 'gatsby'
import Image from '../shared/Image'

export default class About extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            data: contentfulAboutPage {
              teamTitle
              team {
                title

                description
                file {
                  url
                }
              }
            }
          }
        `}
        render={({ data }) => (
          <div className="bg-dark text-white">
            <Container className="pt-5 py-md-5">
              <h2 className="text-center text-primary mb-5  ">
                {data.teamTitle}
              </h2>
              <Row className=" ">
                {data.team.map(
                  ({ title, description, file: { url = '' } = {} }, i) => (
                    <Col
                      md={{
                        size: 5,
                        offset: i * 2,
                      }}
                      className="mb-5 mb-md-3"
                      key={i}
                    >
                      <Image
                        src={url}
                        title={title}
                        alt={description}
                        position={i === 1 && 'top center'}
                        className="w-100 mb-3"
                        size="cover"
                        pb="50%"
                      />

                      <h5 className="text-center">
                        {title}, <br /> {description}
                      </h5>
                    </Col>
                  )
                )}
              </Row>
            </Container>
          </div>
        )}
      />
    )
  }
}
