import React, { Component } from 'react'
import { Container, Row, Col, Input, Button } from 'reactstrap'
import { StaticQuery, graphql, navigate } from 'gatsby'
import Legal from '../shared/Legal'
export default class Hero extends Component {
  state = {
    input: '',
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            data: contentfulAboutPage {
              heroImage {
                title
                description
                file {
                  url
                }
              }
              heroTitle
              heroImageSubtext
              missionTitle
              missionText {
                get: childMarkdownRemark {
                  html
                }
              }
            }
          }
        `}
        render={({ data }) => (
          <div>
            {' '}
            <div className="bg-dark">
              <Container className="py-4 py-md-5">
                <Row className="mb-3">
                  <Col
                    md="7"
                    className="d-flex flex-column justify-content-end"
                  >
                    <h2 className="mb-3 text-gold">{data.heroTitle}</h2>
                    <Row>
                      <Col md="8" lg="6">
                        {' '}
                        <Input
                          type="email"
                          placeholder="Enter Your Email"
                          style={{ height: '60px' }}
                          onChange={e => {
                            this.setState({ input: e.target.value })
                          }}
                          value={this.state.input}
                          className="mt-md-3 d-flex text-center align-middle mb-4 white-btn-gradient"
                        />
                        <div>
                          <Button
                            style={{ height: '60px', lineHeight: 1 }}
                            size="lg"
                            onClick={e => {
                              e.preventDefault()
                              navigate('/contact', {
                                state: {
                                  selected: 'Contact Us',
                                  email: this.state.input,
                                },
                              })
                            }}
                            className="w-100 text-white py-3  text-center   primary-btn-gradient"
                          >
                            Get Started
                          </Button>
                        </div>
                        <Legal dark className="text-center pb-4 pb-md-0" />
                      </Col>
                    </Row>
                  </Col>

                  <Col md={{ size: 5 }}>
                    <img
                      alt={data.heroImage.description}
                      src={data.heroImage.file.url}
                      title={data.heroImage.title}
                      className="img-fluid mb-3 border-40"
                    />
                    <p className="text-gold text-center mb-0">
                      {data.heroImageSubtext}
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="py-5">
              <Container>
                {' '}
                <Row>
                  <Col className="d-flex align-items-center">
                    <div>
                      <h3 className="text-center text-primary">
                        {data.missionTitle}
                      </h3>
                      <div
                        className="text-justify"
                        dangerouslySetInnerHTML={{
                          __html: data.missionText.get.html,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )}
      />
    )
  }
}
