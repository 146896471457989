import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

import Hero from '../components/aboutPage/Hero'
import History from '../components/aboutPage/History'
import About from '../components/aboutPage/About'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'

class Page extends React.Component {
  render() {
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <Hero />
        <About />
        <History />
        <div className="py-3" />
        <Icons />
      </Layout>
    )
  }
}

export default Page

export const query = graphql`
  {
    seo: contentfulAboutPage {
      title
      description
      keywords
    }
  }
`
